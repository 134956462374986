const GalleryPreviewItem = ({ imgBase64 }: { imgBase64: string }) => {
  return (
    <div className="flex h-full w-full flex-none flex-col items-center justify-center overflow-hidden rounded-lg bg-gray-50">
      <img
        src={imgBase64}
        alt="hero placeholder"
        className="aspect-square w-full"
      />
    </div>
  )
}

export default GalleryPreviewItem
