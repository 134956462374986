import { gql } from 'urql'

const QueryTenantCareers = gql`
  query (
    $page: Int
    $limit: Int
    $tenantSlug: String!
    $search: String
    $country: String
    $state: String
    $countryStateId: Int
    $departmentIds: [Int!]
    $remoteStatus: JobRemoteStatus
    $employmentType: JobEmployment
  ) {
    publicJobsList(
      limit: $limit
      page: $page
      tenantSlug: $tenantSlug
      search: $search
      country: $country
      state: $state
      countryStateId: $countryStateId
      departmentIds: $departmentIds
      remoteStatus: $remoteStatus
      employmentType: $employmentType
    ) {
      collection {
        id
        title
        slug
        jobLocations {
          id
          locationId
          address
          country
          city
          state
        }
        permittedFields
        createdAt
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryTenantCareers
