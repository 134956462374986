import Image from 'next/image'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CareerList from '~/components/Careers/[id]/CareerList'
import DropdownLanguage, {
  LANG_ENG_OPTION,
  LANG_JA_OPTION
} from '~/components/DropdownLanguage'
import configuration from '~/configuration'
import { Button } from '~/core/ui/Button'
import { TypographyText } from '~/core/ui/Text'
import { TextButton } from '~/core/ui/TextButton'
import { cn } from '~/core/ui/utils'
import {
  FieldType,
  SectionType
} from '~/lib/features/settings/careers/types/editor'
import {
  fileToBase64,
  renderSectionId
} from '~/lib/features/settings/careers/utilities/editor-ultilities'
import useBoundStore from '~/lib/store'
import { ImageEditor } from '../UploadImagesEditor'
import BenefitPreviewItem from './BenefitPreviewItem'
import GalleryPreviewItem from './GalleryPreviewItem'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '~/core/ui/Accordion'
import IconWrapper from '~/core/ui/IconWrapper'
import { Skeleton } from '~/core/ui/Skeleton'
import { useRouter } from 'next/router'
import pathConfiguration from 'src/configuration/path'
import { TenantType } from '~/lib/features/careers/[id]/types'
import { ITenantDetail } from '~/core/@types/global'

const HeaderPreviewUI: FC<{
  logo?: string
  sectionJobId?: string
  isEditorPage?: boolean
  isLoading?: boolean
}> = ({ logo, sectionJobId, isEditorPage, isLoading }) => {
  const { t } = useTranslation()
  const [uploadLogo, setUploadLogo] = useState<string>()
  const scrollToJobsSection = useCallback(() => {
    sectionJobId &&
      document
        .querySelector(`.${renderSectionId(sectionJobId)}`)
        ?.scrollIntoView()
  }, [sectionJobId])

  useEffect(() => {
    if (typeof logo === 'object' && !!logo) {
      fileToBase64(logo).then((imgBase64: any) => {
        setUploadLogo(imgBase64)
      })
    } else setUploadLogo(logo)
  }, [logo])

  return (
    <div className="mx-auto flex w-full max-w-[1250px] items-center justify-between px-5 py-[11px]">
      {isLoading ? (
        <Skeleton className="h-10 w-[92px] rounded" />
      ) : uploadLogo ? (
        <img
          src={uploadLogo}
          width="auto"
          height={40}
          alt="logo preview"
          style={{
            height: 40,
            width: 'auto'
          }}
        />
      ) : (
        <Image
          src="/img/logo/logo-preview.png"
          width={92}
          height={40}
          alt="logo preview"
        />
      )}
      <Button
        label={`${t('button:viewJobs')}`}
        size="lg"
        className="brand-color-button"
        onClick={isEditorPage ? undefined : scrollToJobsSection}
      />
    </div>
  )
}

const FooterPreviewUI: FC<{
  logo?: string
  isEditorPage?: boolean
  showSwitchLanguages?: boolean
  companyName?: string
}> = ({ logo, isEditorPage, showSwitchLanguages, companyName }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [uploadLogo, setUploadLogo] = useState<string>()
  useEffect(() => {
    if (typeof logo === 'object' && !!logo) {
      fileToBase64(logo).then((imgBase64: any) => {
        setUploadLogo(imgBase64)
      })
    } else setUploadLogo(logo)
  }, [logo])
  return (
    <div className="mt-[100px] border-t border-t-gray-100">
      <div className="mx-auto flex w-full max-w-[1250px] items-center justify-between px-5 py-4">
        <TypographyText className="text-gray-700">
          © 2024 {companyName}.
        </TypographyText>
        {uploadLogo ? (
          <img
            src={uploadLogo}
            width="auto"
            height={40}
            alt="logo preview"
            style={{
              height: 40,
              width: 'auto'
            }}
          />
        ) : (
          <Image
            src="/img/logo/logo-preview.png"
            width={92}
            height={40}
            alt="logo preview"
          />
        )}
        <div className="flex items-center space-x-4">
          <div className="flex space-x-1">
            <TypographyText className="text-gray-700">
              {t('label:powered_by')}
            </TypographyText>
            <TextButton
              label={configuration.appDomain}
              size="lg"
              underline={false}
              classNameText="brand-color-text"
              onClick={() => {
                window.open(
                  router?.query?.['id'] && !isEditorPage
                    ? `${configuration.appUrl}?utm_campaign=careers_page&utm_content=${router?.query?.['id']}&utm_source=careers_page`
                    : configuration.appUrl
                )
              }}
            />
          </div>
          {showSwitchLanguages && (
            <DropdownLanguage
              languagesOptions={[LANG_ENG_OPTION, LANG_JA_OPTION]}
              preventAction={!!isEditorPage}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const HeroSectionPreviewUI: FC<{ section: SectionType }> = ({ section }) => {
  const { t } = useTranslation()
  const data = useMemo(
    () =>
      (section?.fields || []).reduce(
        (result, field) => {
          return {
            ...result,
            ...(field?.keyName ? { [field.keyName]: field.values } : {})
          }
        },
        {
          title: '',
          subtitle: '',
          hero_image: []
        } as {
          title: string
          subtitle: string
          hero_image: Array<ImageEditor>
        }
      ),
    [section?.fields]
  )

  return (
    <div
      className={cn(
        'relative h-[480px] overflow-hidden',
        renderSectionId(section.id)
      )}>
      <div className="absolute right-0 top-0 z-[1] flex h-full w-full items-center justify-center bg-[#F2F5F7]">
        <div className="absolute bottom-0 right-0 h-[221px] w-full bg-[linear-gradient(180deg,rgba(115,115,115,0.00)_0%,#3B3B3B_100%)]" />
        {data?.hero_image?.[0]?.imgBase64 ? (
          <img
            src={data?.hero_image?.[0]?.imgBase64}
            width={1440}
            height={480}
            alt="hero placeholder"
            className="h-auto w-full"
          />
        ) : (
          <Image
            src={'/img/feature/careers/hero_placeholder.png'}
            width={168}
            height={128}
            alt="hero placeholder"
          />
        )}
      </div>
      <div className="absolute bottom-0 right-0 z-10 w-full pb-8 text-center">
        {data?.title && (
          <TypographyText className="mb-3 text-[45px] font-bold leading-none text-white">
            {data?.title}
          </TypographyText>
        )}
        {data.subtitle && (
          <TypographyText className="text-white">
            {data.subtitle}
          </TypographyText>
        )}
      </div>
    </div>
  )
}

const SectionTile: FC<{ content?: string; className?: string }> = ({
  content,
  className
}) =>
  content ? (
    <TypographyText
      className={cn(
        'title-color-text text-center text-[45px] font-bold leading-none text-gray-700',
        className
      )}>
      {content}
    </TypographyText>
  ) : null
const SectionSubtile: FC<{ content?: string; className?: string }> = ({
  content,
  className
}) =>
  content ? (
    <TypographyText
      className={cn(
        'secondary-color-text text-center text-xl text-gray-700',
        className
      )}>
      {content}
    </TypographyText>
  ) : null

const AboutUsSectionPreviewUI: FC<{ section: SectionType }> = ({ section }) => {
  const { t, i18n } = useTranslation()
  const data = useMemo(
    () =>
      (section?.fields || []).reduce(
        (result, field) => {
          return {
            ...result,
            ...(field?.keyName ? { [field.keyName]: field.values } : {})
          }
        },
        {
          title: '',
          subtitle: '',
          company_description: {
            en: '',
            ja: ''
          }
        } as {
          title: string
          subtitle: string
          company_description: {
            [key: string]: string
          }
        }
      ),
    [section?.fields]
  )

  return (
    <div className={cn('px-5', renderSectionId(section.id))}>
      <SectionTile content={data?.title} className="mb-3" />
      <SectionSubtile content={data?.subtitle} className="mb-6" />
      <TypographyText className="secondary-color-text mx-auto max-w-[700px] whitespace-pre-wrap text-center text-base text-gray-700">
        {data?.company_description?.[i18n.language]}
      </TypographyText>
    </div>
  )
}

const JobsSectionPreviewUI: FC<{
  section: SectionType
  isEditorPage?: boolean
  tenant?: TenantType | ITenantDetail
}> = ({ section, isEditorPage, tenant }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const data = useMemo(
    () =>
      (section?.fields || []).reduce(
        (result, field) => {
          return {
            ...result,
            ...(field?.keyName ? { [field.keyName]: field.values } : {})
          }
        },
        {
          title: '',
          subtitle: ''
        } as {
          title: string
          subtitle: string
        }
      ),
    [section?.fields]
  )
  const { user } = useBoundStore()

  return (
    <div
      className={cn(
        'mx-auto w-full max-w-[804px]',
        renderSectionId(section.id)
      )}>
      <SectionTile content={data?.title} className="mb-3" />
      <SectionSubtile content={data?.subtitle} className="mb-10" />

      <div className="relative">
        {/* Prevent user can action on career list */}
        {isEditorPage && <div className="absolute z-[1] h-full w-full"></div>}
        <CareerList
          tenantSlug={
            router.asPath === pathConfiguration?.settings?.careers_editor
              ? user?.currentTenant?.slug
              : router?.query?.id
          }
          careerPageSetting={user?.currentTenant?.careerSiteSettings}
          tenant={
            {
              ...tenant,
              ...(!!user?.currentTenant?.companyKind
                ? { companyKind: String(user?.currentTenant?.companyKind) }
                : {})
            } as TenantType
          }
        />
      </div>
    </div>
  )
}

const BenefitsSectionPreviewUI: FC<{
  section: SectionType
}> = ({ section }) => {
  const data = useMemo(
    () =>
      (section?.fields || []).reduce(
        (result, field) => {
          return {
            ...result,
            ...(field?.keyName
              ? { [field.keyName]: field.values || field.sections }
              : {})
          }
        },
        {
          title: '',
          subtitle: ''
        } as {
          title: string
          subtitle: string
          benefits?: Array<{
            fields: Array<FieldType>
          }>
        }
      ),
    [section?.fields]
  )
  const childSectionClassName = useMemo(
    () =>
      section.fields
        .filter((field) => (field.sections || []).length > 0)
        .map((field) =>
          (field.sections || [])
            .map((childSection) => renderSectionId(childSection.id))
            .join(' ')
        )
        .join(' '),
    [section]
  )
  return (
    <div
      className={cn(
        'mx-auto w-full max-w-[1440px]',
        renderSectionId(section.id),
        childSectionClassName
      )}>
      <SectionTile content={data?.title} className="mb-3" />
      <SectionSubtile content={data?.subtitle} className="mb-10" />
      <div className="px-10">
        <div className="-ml-5 -mt-5 flex w-full flex-wrap items-center justify-center [&>div]:ml-5 [&>div]:mt-5 [&>div]:w-[calc(33.33%_-_20px)]">
          {data?.benefits?.map((benefit, index) => {
            const { fields } = benefit
            const icon = fields.filter((field) => field.keyName === 'icon')[0]
              ?.values
            const title = fields.filter((field) => field.keyName === 'title')[0]
              ?.values
            const description = fields.filter(
              (field) => field.keyName === 'description'
            )[0]?.values
            return (
              <BenefitPreviewItem
                icon={icon}
                title={title}
                description={description}
                key={index}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

const GallerySectionPreviewUI: FC<{
  section: SectionType
}> = ({ section }) => {
  const { t } = useTranslation()

  const data = useMemo(
    () =>
      (section?.fields || []).reduce(
        (result, field) => {
          return {
            ...result,
            ...(field?.keyName ? { [field.keyName]: field.values } : {})
          }
        },
        {
          title: '',
          subtitle: ''
        } as {
          title: string
          subtitle: string
          images: ImageEditor[]
        }
      ),
    [section?.fields]
  )

  return (
    <div
      className={cn(
        'mx-auto w-full max-w-[1316px]',
        renderSectionId(section.id)
      )}>
      <SectionTile content={data?.title} className="mb-3" />
      <SectionSubtile content={data?.subtitle} className="mb-10" />
      <div className="-ml-5 -mt-5 flex flex w-full flex-wrap items-center justify-center px-10 [&>div]:ml-5 [&>div]:mt-5 [&>div]:w-[calc(25%_-_20px)]">
        {(data?.images || []).length > 0 ? (
          (data?.images || []).map((image, index) =>
            !image.imgBase64 ? null : (
              <GalleryPreviewItem imgBase64={image.imgBase64} key={index} />
            )
          )
        ) : (
          <>
            {[1, 2, 3, 4].map((item) => (
              <GalleryPreviewItem
                imgBase64={'/img/feature/careers/default_gallery.jpg'}
                key={item}
              />
            ))}
          </>
        )}
      </div>
    </div>
  )
}

const FAQsSectionPreviewUI: FC<{
  section: SectionType
  isEditorPage?: boolean
}> = ({ section, isEditorPage }) => {
  const { t } = useTranslation()
  const [currentItem, setCurrentItem] = useState<string>()
  const data = useMemo(
    () =>
      (section?.fields || []).reduce(
        (result, field) => {
          return {
            ...result,
            ...(field?.keyName
              ? { [field.keyName]: field.values || field.sections }
              : {})
          }
        },
        {
          title: '',
          subtitle: ''
        } as {
          title: string
          subtitle: string
          faq?: Array<{
            fields: Array<FieldType>
          }>
        }
      ),
    [section?.fields]
  )
  const childSectionClassName = useMemo(
    () =>
      section.fields
        .filter((field) => (field.sections || []).length > 0)
        .map((field) =>
          (field.sections || [])
            .map((childSection) => renderSectionId(childSection.id))
            .join(' ')
        )
        .join(' '),
    [section]
  )

  return (
    <div
      className={cn(
        'mx-auto w-full max-w-[804px]',
        renderSectionId(section.id),
        childSectionClassName
      )}>
      <SectionTile content={data?.title} className="mb-3" />
      <SectionSubtile content={data?.subtitle} className="mb-10" />
      <div className="relative flex items-center justify-center px-10">
        {isEditorPage && <div className="absolute top-0 h-full w-full" />}
        <Accordion
          collapsible
          onValueChange={(e) => {
            setCurrentItem(e)
          }}
          className="w-[700px] space-y-3"
          type="single">
          {data?.faq?.map((faqItem, index) => {
            const getItem = `item-${index + 1}`
            const isSelected = currentItem === getItem
            const { fields } = faqItem

            const question = fields.filter(
              (field) => field.keyName === 'question'
            )[0]?.values
            const answer = fields.filter(
              (field) => field.keyName === 'answer'
            )[0]?.values
            return !!question ? (
              <AccordionItem
                key={index}
                className="rounded-lg border"
                value={getItem}>
                <AccordionTrigger className="flex flex-row items-center space-x-1 px-6 py-4">
                  <TypographyText className="flex-1 text-lg font-medium text-gray-900">
                    {question}
                  </TypographyText>
                  <div className="min-h-[14px] min-w-[14px]">
                    <IconWrapper
                      name={isSelected ? 'MinusIcon' : 'PlusIcon'}
                      size={18}
                      className={cn('text-gray-500 transition-all')}
                    />
                  </div>
                </AccordionTrigger>
                <AccordionContent className="px-[24px] pb-[15px] text-base font-normal text-gray-700">
                  {answer}
                </AccordionContent>
              </AccordionItem>
            ) : null
          })}
        </Accordion>
      </div>
    </div>
  )
}

export {
  HeaderPreviewUI,
  FooterPreviewUI,
  HeroSectionPreviewUI,
  AboutUsSectionPreviewUI,
  JobsSectionPreviewUI,
  BenefitsSectionPreviewUI,
  GallerySectionPreviewUI,
  FAQsSectionPreviewUI
}
