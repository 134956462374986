import { gql } from 'urql'

const QueryCareerLocationsAgency = gql<
  {
    jobLocsByCountryStateList: {
      collection: {
        address: string
        country: string
        state: string
      }[]
    }
  },
  { page?: number; limit?: number; tenantSlug: string; search?: string }
>`
  query ($page: Int, $limit: Int, $tenantSlug: String!, $search: String) {
    jobLocsByCountryStateList(
      tenantSlug: $tenantSlug
      page: $page
      limit: $limit
      search: $search
    ) {
      collection {
        address
        country
        state
        countryStateId
      }
    }
  }
`

export default QueryCareerLocationsAgency
