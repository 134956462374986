import { TypographyText } from '~/core/ui/Text'

const BenefitPreviewItem = ({
  icon,
  title,
  description
}: {
  icon: string
  title: string
  description: string
}) => {
  return (
    <div className="flex h-auto w-full flex-col items-center rounded-lg bg-gray-50 px-8 py-10">
      <div className="flex h-20 w-20 items-center justify-center rounded-[40px] bg-white">
        <TypographyText className="text-center text-4xl font-semibold">
          {icon}
        </TypographyText>
      </div>
      <TypographyText className="mt-6 text-center text-xl font-semibold">
        {title}
      </TypographyText>
      <TypographyText className="mt-2 text-center text-base font-normal">
        {description}
      </TypographyText>
    </div>
  )
}

export default BenefitPreviewItem
