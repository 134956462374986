import { ImageEditor } from '~/components/Settings/Careers/Editor/UploadImagesEditor'
import { CareerPageSettingType } from '~/lib/features/careers/[id]/types'
import { SectionsListType } from '~/lib/features/settings/careers/types/editor'
import { CareerSettingType } from '../types'
import {
  FieldKind,
  FieldResponseType,
  FieldType,
  SectionableTypeKind,
  SectionResponseType,
  SectionType,
  TemplateResponseType
} from '../types/editor'
import { SETTING_COLOR } from '../utilities/enum'

// const formatDefaultCareerSetting = ({
//   sectionKey,
//   fieldKey,
//   careerSiteSetting
// }: {
//   sectionKey: string
//   fieldKey?: string
//   careerSiteSetting?: CareerPageSettingType
// }) => {
//   if (sectionKey === 'hero' && fieldKey === 'title') {
//     return careerSiteSetting?.page_title
//   }
//   if (sectionKey === 'about_us' && fieldKey === 'company_description') {
//     return careerSiteSetting?.description
//   }

//   return undefined
// }

export const mappingFieldFromResponse = (
  field: FieldResponseType,
  parentSection: SectionResponseType,
  careerSiteSetting?: CareerPageSettingType
): FieldType => {
  return {
    id: field.id,
    name: field.name,
    keyName: field?.key || '',
    index: field?.index,
    required: field?.required,
    fieldKind: field.fieldKind as FieldKind,
    sections: (field?.sections || []).map((section) =>
      mappingSectionFromResponse(section, careerSiteSetting)
    ),
    values: {
      string: field.values?.content,
      mult_lang_text: field.values,
      text: field.values?.content,
      icon_dropdown: field.values?.content,
      file: (field.images || []).map((attachment) => ({
        ...attachment,
        imgBase64: attachment?.file,
        status: 'success'
      })),
      files: (field.images || []).map((attachment) => ({
        ...attachment,
        imgBase64: attachment?.file,
        status: 'success'
      }))
    }[field.fieldKind]
  }
}

export const mappingSectionFromResponse = (
  section: SectionResponseType,
  careerSiteSetting?: CareerPageSettingType
): SectionType => {
  return {
    id: section.id,
    name: section.name,
    key: section.key,
    lock: !!section.locked,
    index: section.index,
    visibility: section.hidden !== null ? !section.hidden : undefined,
    isDisabledVisibility:
      section.hiddenChangable !== null ? !section.hiddenChangable : undefined,
    sectionableType: section.sectionableType as SectionableTypeKind,
    fields: (section?.fields || []).map((field) =>
      mappingFieldFromResponse(field, section, careerSiteSetting)
    )
  }
}

//Function format data when submit to update career template
export const formatFieldValues = ({
  values,
  fieldKind
}: {
  values?: any
  fieldKind: FieldKind
}) => {
  return {
    values: {
      string: {
        content: values
      },
      text: {
        content: values
      },
      mult_lang_text: values,
      file: undefined,
      files: undefined,
      icon_dropdown: {
        content: values
      }
    }[fieldKind],
    images:
      fieldKind === 'file' || fieldKind === 'files'
        ? (values || [])
            ?.filter(
              (file: ImageEditor) => file.status !== 'failed' && !file.id
            )
            ?.map(
              (file: {
                id?: string
                file?: File
                img: File
                imgBase64: string
              }) => file.img
            )
        : []
  }
}

export const formatField = (field: FieldType): FieldResponseType => {
  return {
    id: field.id,
    key: field.keyName,
    name: field.name,
    index: field.index,
    required: field.required,
    fieldKind: field.fieldKind,
    sections: formatSection(field?.sections || []),
    ...formatFieldValues({ values: field.values, fieldKind: field.fieldKind })
  }
}

export const formatSection = (
  sectionList: SectionType[]
): Array<SectionResponseType> => {
  return (sectionList || []).map((section) => ({
    id: section.id,
    name: section.name || '',
    key: section.key,
    index: section?.index || 0,
    locked: !!section.lock,
    hidden: section.visibility !== undefined ? !section.visibility : null,
    hiddenChangable:
      section.isDisabledVisibility !== undefined
        ? !section.isDisabledVisibility
        : null,
    sectionableType: section.sectionableType,
    fields: section.fields.map((field) => formatField(field))
  }))
}

export const mappingTemplateRespToSectionAndConfigData = (
  template: TemplateResponseType,
  careerSiteSetting?: CareerPageSettingType
) => {
  const templateConfig = {
    logo: template?.setting?.images?.find(
      (image) => image.key === 'career_site_logo'
    )?.file,
    favicon: template?.setting?.images?.find(
      (image) => image.key === 'career_site_favicon'
    )?.file,
    brandColor: template?.references?.brand_color || SETTING_COLOR.brandColor,
    titleColor: template?.references?.title_color || SETTING_COLOR.titleColor,
    secondaryColor:
      template?.references?.subtitle_color || SETTING_COLOR.secondaryColor
  }

  const sections = template?.sections?.reduce(
    (result, section) => {
      return {
        lock: [
          ...(result?.lock || []),
          ...(section.locked
            ? [mappingSectionFromResponse(section, careerSiteSetting)]
            : [])
        ],
        draggable: [
          ...(result?.draggable || []),
          ...(!section.locked
            ? [mappingSectionFromResponse(section, careerSiteSetting)]
            : [])
        ]
      }
    },
    {
      lock: [],
      draggable: []
    } as SectionsListType
  )
  return {
    templateConfig,
    sections
  }
}

export const mappingSectionTypeToSectionListType = (
  sections: SectionType[]
): SectionsListType => {
  return sections.reduce(
    (result, section) => ({
      lock: [...(result.lock || []), ...(section.lock ? [section] : [])],
      draggable: [
        ...(result.draggable || []),
        ...(!section.lock ? [section] : [])
      ]
    }),
    {
      lock: [],
      draggable: []
    } as SectionsListType
  )
}
