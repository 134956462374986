import { renderNewSectionId } from './editor-ultilities'

export const DEPARTMENT_SHOW_TOP_LEVEL = 'show_top_level'
export const DEPARTMENT_SHOW_ALL = 'show_all'

export const LINK_FIND_GA_ID =
  'https://support.google.com/analytics/answer/9539598#find-G-ID'

export const LANGUAGES_VALUE = {
  english: 'en',
  japanese: 'ja'
}

export const SETTING_COLOR = {
  brandColor: '#5D5AF9',
  titleColor: '#1D1E27',
  secondaryColor: '#313543'
}

export const TEMPLATE_SECTION_DEFAULT: { [key: string]: any } = {
  benefits: {
    name: 'Benefit title',
    key: 'benefit',
    lock: false,
    sectionableType: 'Field',
    fields: [
      {
        index: 1,
        name: 'Icon',
        keyName: 'icon',
        fieldKind: 'icon_dropdown',
        values: '',
        sections: []
      },
      {
        index: 2,
        name: 'Title',
        keyName: 'title',
        fieldKind: 'string',
        values: 'Benefit title',
        sections: []
      },
      {
        index: 3,
        name: 'Description',
        keyName: 'description',
        fieldKind: 'text',
        values: 'Benefit description',
        sections: []
      }
    ]
  },
  faq: {
    name: 'Question goes here',
    keyName: 'faq',
    lock: false,
    sectionableType: 'Field',
    fields: [
      {
        index: 1,
        name: 'Question',
        keyName: 'question',
        fieldKind: 'string',
        sections: [],
        values: 'Question goes here'
      },
      {
        index: 2,
        name: 'Answer',
        keyName: 'answer',
        fieldKind: 'text',
        values: 'Answer goes here',
        sections: []
      }
    ]
  }
}
